

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'allerBd';
  src: local('allerBd'), url('./fonts/allerBd.ttf') format('truetype');
  
}
@font-face {
  font-family: 'NotoSerifCondensedSemiBoldItalic';
  src: local('allerBd'), url('./fonts/NotoSerifCondensedSemiBoldItalic.ttf') format('truetype');

}
@font-face {
  font-family: 'NotoSerifCondensedBlackItalic';
  src: local('allerBd'), url('./fonts/NotoSerifCondensedBlackItalic.ttf') format('truetype');

}
@font-face {
  font-family: 'AllerLtlt';
  src: local('allerBd'), url('./fonts/AllerLtIt.ttf') format('truetype');

}
@font-face {
  font-family: 'AllerRg';
  src: local('AllerRg'), url('./fonts/AllerRg.ttf') format('truetype');

}
@font-face {
  font-family: 'AllerLt';
  src: local('AllerLt'), url('./fonts/AllerLt.ttf') format('truetype');

}
@font-face {
  font-family: 'NotoSerifExtraBold';
  src: local('NotoSerifExtraBold'), url('./fonts/NotoSerifExtraBold.ttf') format('truetype');

}